























































































import { Component, Vue, Provide } from 'vue-property-decorator';
import GroupList from '@/components/GroupList.vue';

@Component({
  components: {
    GroupList,
  },
})

export default class Admin extends Vue {
  hash = process.env.VUE_APP_GIT_HASH

  tag = process.env.VUE_APP_GIT_TAG

  @Provide() auth = false;

  @Provide() code = '';

  @Provide() error = '';

  onPin(pin: string) {
    this.code = `${this.code}${pin}`;
  }

  onLogin() {
    if (this.code === '4655') {
      this.auth = true;
      this.$bvToast.toast('Successfully logged in.', {
        variant: 'success',
        noCloseButton: true,
      });
    } else {
      this.onClear();
      this.$bvToast.toast('Sorry, the PIN was incorrect. Please try again.', {
        variant: 'danger',
        noCloseButton: true,
      });
    }
  }

  onClear() {
    this.code = '';
  }

  get version(): string {
    return `${this.tag} #${this.hash}`;
  }

  get pin(): string {
    return '*'.repeat(this.code.length);
  }
}
